export default {
  columns: {
    sssp: {
      title: '手术视频',
      icon: 'sszb_ss_icon_nor@2x.png',
      activeIcon: 'sszb_ss_icon_alt@2x.png'
    },
    hyzb: {
      title: '会议直播',
      icon: 'hyzb_ss_icon_nor@2x.png',
      activeIcon: 'hyzb_ss_icon_alt@2x.png'
    },
    jcbl: {
      title: '精彩病例',
      icon: 'jcbl_ss_icon_nor@2x.png',
      activeIcon: 'jcbl_ss_icon_alt@2x.png'
    },
    fhjc: {
      title: '缝合教程',
      icon: 'fhjc_ss_icon_nor@2x.png',
      activeIcon: 'fhjc_ss_icon_alt@2x.png'
    },
    xzzj: {
      title: '询证证据',
      icon: 'xzzj_ss_icon_nor@2x.png',
      activeIcon: 'xzzj_ss_icon_alt@2x.png'
    },
    spzq: {
      title: '视频专区',
      icon: 'spzq_ss_icon_nor@2x.png',
      activeIcon: 'spzq_ss_icon_alt@2x.png'
    },
    xssxx: {
      title: '新术式学习',
      icon: 'sssxx_ss_icon_nor@2x.png',
      activeIcon: 'sssxx_ss_icon_alt@2x.png'
    },
    mjjt: {
      title: '名家讲堂',
      icon: 'mjjt_ss_icon_nor@2x.png',
      activeIcon: 'mjjt_ss_icon_alt@2x.png'
    },
    xzzl: {
      title: '循证资料',
      icon: 'xzzj_ss_icon_nor@2x.png',
      activeIcon: 'xzzj_ss_icon_alt@2x.png'
    },
    xzcl: {
      title: '循证材料',
      icon: 'xzzj_ss_icon_nor@2x.png',
      activeIcon: 'xzzj_ss_icon_alt@2x.png'
    },
    hdpt: {
      title: '活动平台',
      icon: 'hdpt_ss_icon_nor@2x.png',
      activeIcon: 'hdpt_ss_icon_alt@2x.png'
    },
    hdwk: {
      title: '活动外科',
      icon: 'hdpt_ss_icon_nor@2x.png',
      activeIcon: 'hdpt_ss_icon_alt@2x.png'
    },
    gsjd: {
      title: '共识解读',
      icon: 'gsjd_ss_icon_nor@2x.png',
      activeIcon: 'gsjd_ss_icon_alt@2x.png'
    },
    gszn: {
      title: '共识指南',
      icon: 'gsjd_ss_icon_nor@2x.png',
      activeIcon: 'gsjd_ss_icon_alt@2x.png'
    },
    hyrd: {
      title: '行业热点',
      icon: 'hyrd_ss_icon_nor@2x.png',
      activeIcon: 'hyrd_ss_icon_alt@2x.png'
    },
    jdkj: {
      title: '尖端科技',
      icon: 'jdkj_ss_icon_nor@2x.png',
      activeIcon: 'jdkj_ss_icon_alt@2x.png'
    },
    yxtj: {
      title: '用线推荐',
      icon: 'yxtj_ss_icon_nor@2x.png',
      activeIcon: 'yxtj_ss_icon_alt@2x.png'
    },
    xwfhjc: {
      title: '胸外缝合教程',
      icon: 'fhjc_ss_icon_nor@2x.png',
      activeIcon: 'fhjc_ss_icon_alt@2x.png'
    },
    xwqns: {
      title: '胸外青年说',
      icon: 'xwqns_ss_icon_nor@2x.png',
      activeIcon: 'xwqns_ss_icon_alt@2x.png'
    },
    jdfhcl: {
      title: '尖端缝合材料',
      icon: 'jdkj_ss_icon_nor@2x.png',
      activeIcon: 'jdkj_ss_icon_alt@2x.png'
    },
    xzllds: {
      title: '寻找丽量大赛',
      icon: 'xzllds_ss_icon_nor@2x.png',
      activeIcon: 'xzllds_ss_icon_alt@2x.png'
    }
  },
  jnpxColumns: {
    jnzb: {
      title: '技能直播',
      icon: 'hyzb_ss_icon_nor@2x.png',
      activeIcon: 'hyzb_ss_icon_alt@2x.png'
    },
    jnkc: {
      title: '技能课程',
      icon: 'gsjd_ss_icon_nor@2x.png',
      activeIcon: 'gsjd_ss_icon_alt@2x.png'
    },
    fxxkt: {
      title: '缝线小课堂',
      icon: 'fhjc_ss_icon_nor@2x.png',
      activeIcon: 'fhjc_ss_icon_alt@2x.png'
    },
    qwkt: {
      title: '趣味课堂',
      icon: 'mjjt_ss_icon_nor@2x.png',
      activeIcon: 'mjjt_ss_icon_alt@2x.png'
    }
  }
}